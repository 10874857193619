// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  background-color: rgba(255, 135, 0, 0.14);
}

.App-logo {
  pointer-events: none;
  animation: pulse 1.5s infinite;
  padding:30px;
  width:75vw;
  max-width:300px;
}


.App-link {
  color: #61dafb;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,oBAAoB;EACpB,8BAA8B;EAC9B,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".App {\n  display:flex;\n  justify-content: center;\n  align-items: center;\n  height:100vh;\n  background-color: rgba(255, 135, 0, 0.14);\n}\n\n.App-logo {\n  pointer-events: none;\n  animation: pulse 1.5s infinite;\n  padding:30px;\n  width:75vw;\n  max-width:300px;\n}\n\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n  }\n\n  50% {\n    transform: scale(1.1);\n  }\n\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
